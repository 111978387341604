import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import {
  Category, CategoryOutlined,
  Close,
  Info,
  List,
  Memory,
  SettingsBackupRestore,
  ThumbUp,
  ThumbUpOutlined,
  ViewModule,
} from '@material-ui/icons';
import { Container } from '@material-ui/core';
import { ThemeContext } from 'styled-components';

import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { PageContentUI } from '../Common/styled/PageContentUI';
import Loading from '../Common/Loading';
import { GET_ADVANCED_TOOLS } from '../../queries/AdvancedTools/AdvancedTools';
import { GET_ADVANCED_TOOLS_FAVOURITES } from '../../queries/AdvancedTools/Favorites';
import { SectionBar } from '../../componentsUI/SectionBar';
import useStyles from './styled/StudyAdvancedToolsMakeStyles';
import TableCollapse from '../../componentsUI/tableCollapse';
import { isEmpty } from '../../utils/ObjectUtils';
import { StudyAdvancedToolUseDialog } from './modal/StudyAdvancedToolUseDialog';
import { AdvancedToolUse } from './use/AdvancedToolUse';
import { UI_TOOL_STATUS_DEFAULT } from '../../utils/advancedToolUtils';
import { AdvancedToolSetFavorite } from './use/AdvancedToolSetFavorite';
import { Span } from '../Common/styled/Text';
import FlexGridTable from '../../componentsUI/FlexGridTable';
import { InnerHTML } from '../Common/InnerHTML';
import { AdvancedToolCardImageItem } from './card/AdvancedToolCardImageItem';
import { AdvancedToolCardImageOverlay } from './card/AdvancedToolCardImageOverlay';
import { AdvancedToolCardImageFooter } from './card/AdvancedToolCardImageFooter';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { getStudyApplicationPath } from '../Study/utils/StudyApplicationPath';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import { AdvancedToolFilterNav } from './filter/AdvancedToolFilterNav';
import { FilterCategory, DefinedFilterCategory } from './filter/AdvancedToolFilterModel';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

export const StudyAdvancedToolsView = ({ study }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const currentHospital = useSelector((state) => state.hospital);

  const hospital = study.hospital ? study.hospital : study.medicalCase.patient.hospital;
  const availableTools = hospital.availableAdvancedTools.map((tool) => tool.uuid);

  if (currentHospital && currentHospital.uuid !== hospital.uuid) {
    setHospitalEnvironment({ uuid: hospital.uuid, dispatch, context });
  }

  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [viewFilters, setViewFilters] = useState(false);

  const [useTool, setUseTool] = useState(false);
  const [usingTool, setUsingTool] = useState(false);
  const [uiToolStatus, setUiToolStatus] = useState(UI_TOOL_STATUS_DEFAULT);
  const [triggerToolFavoriteEvent, setTriggerToolFavoriteEvent] = useState(false);
  const [viewAsList, setViewAsList] = useState(false);
  const [updatingFavorite, setUpdatingFavorite] = useState(false);

  const { data: dataFav } = useQuery(GET_ADVANCED_TOOLS_FAVOURITES);
  const { loading: loadingIA, data: dataIA, fetchMore } = useQuery(
    GET_ADVANCED_TOOLS,
    { fetchPolicy: 'cache-and-network' },
  );

  const getAdvancedTools = (tools, favorites) => (tools.map((tool) => (
    { ...tool,
      node: { ...tool.node,
        favorite: favorites.some((fav) => fav.advancedToolUuid === tool.node.uuid),
      },
    }
  )));

  const fetchMoreFunction = (fetchMoreCb) => {
    const { endCursor } = dataIA.advancedTools.pageInfo;
    const query = GET_ADVANCED_TOOLS;
    const variables = { cursor: endCursor, first: 12 };

    fetchMore({
      query,
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.advancedTools.edges;

        return newEdges.length ? {
          ...previousResult,
          advancedTools: {
            ...previousResult.advancedTools,
            pageInfo: fetchMoreResult.advancedTools.pageInfo,
            edges: [...previousResult.advancedTools.edges, ...newEdges],
          },
        } : previousResult;
      },
    }).then(({ data: fetchMoreData }) => (
      fetchMoreData.advancedTools.pageInfo.hasNextPage && fetchMoreCb()
    ));
  };

  const advancedTools = dataIA && dataIA.advancedTools
    && getAdvancedTools(dataIA.advancedTools.edges, dataFav.userFavoriteAdvancedTools)
      .filter((edge) => availableTools.indexOf(edge.node.uuid) !== -1);

  const inputCategories = [
    new FilterCategory('speciality', 's', 'medical.specialties', 'speciality', (key) => t(key.toLowerCase())),
    new FilterCategory('category', 'c', 'image.modalities', 'category'),
    new FilterCategory('bodyRegion', 'r', 'anatomies', 'bodyRegion', (key) => t(key.toLowerCase())),
    new FilterCategory('provider', 'p', 'providers', 'provider.name', null, (key) => key.replace(/ /g, '_')),
  ];

  const buildFiltersIndex = (attr, pre) => `${pre}_${attr}`.replace(/ /g, '_');

  const categoryOptions = advancedTools && advancedTools.length && inputCategories.map((category) => ({
    id: category.id,
    label: category.label,
    render: category.render,
    elements: [...new Set(advancedTools.map((tool) => category.find(tool.node)))]
      .filter((elem) => elem !== null),
    filters: [...new Set(advancedTools.map((tool) => category.find(tool.node)))]
      .filter((elem) => elem !== null)
      .map((option) => buildFiltersIndex(option, category.prefix)),
  }));

  const certificationTools = ['ceCertification', 'fdaCertification', 'research'];

  const booleanCategories = [
    new DefinedFilterCategory('certifications', 'b', 'health.certifications'),
  ];

  booleanCategories[0]
    .setFilters(certificationTools)
    .setLabels(['CE', 'FDA', t('research')]);

  const options = categoryOptions && categoryOptions.length
    && [...categoryOptions, ...booleanCategories];

  const initialFilters = options && options.length && options
    .map((category) => category.filters)
    .reduce((pre, cur) => [...pre, ...cur])
    .reduce((obj, ind) => ({ ...obj, [ind]: false }), {});

  const resetFilters = () => { setFilters({ ...initialFilters }); };
  if (initialFilters && isEmpty(filters)) resetFilters();

  const updateFilters = (f) => {
    setSelected([]);
    setFilters(f);
  };

  const getFilterGroupObject = (categories) => (
    categories
      .map((category) => ({ [category.id]: Object.keys(filters)
        .map((index) => index)
        .filter((filter) => filter.substring(0, 2) === `${category.prefix}_`)
        .filter((index) => filters[index] === true)
        .map((filter) => filter.substring(2)),
      }))
      .reduce((obj, ind) => ({ ...obj, ...ind }), {})
  );

  const inputFilters = getFilterGroupObject(inputCategories);
  const booleanFilters = getFilterGroupObject(booleanCategories);

  let displayTools = advancedTools;

  inputCategories.forEach((cat) => {
    displayTools = displayTools && displayTools
      .filter((tool) => (inputFilters[cat.id].length
        ? inputFilters[cat.id].includes(cat.match(tool.node))
        : true));
  });

  booleanCategories.forEach((cat) => {
    displayTools = displayTools && displayTools
      .filter((tool) => (booleanFilters[cat.id].length
        ? booleanFilters[cat.id].filter((field) => tool.node[field] === true).length
        : true));
  });

  const selectedAdvancedTool = (displayTools && selected.length > 0)
    && displayTools[selected[0]] && displayTools[selected[0]].node;

  const [showAdvancedToolUse, setShowAdvancedToolUse] = useState(false);

  const getAdvancedToolName = (item) => (
    <Span className={item.node.favorite ? classes.favorite : classes.tool}>{item.node.name}</Span>
  );

  const getAdvancedToolData = (item) => {
    const isSelected = selectedAdvancedTool && selectedAdvancedTool.uuid === item.node.uuid;
    const chipsClass = isSelected ? classes.chips : `${classes.chips} tool-selected`;
    return (
      <Box className={`${classes.toolSubData} ${isSelected && 'tool-selected'}`}>
        <Box className="provider-name">{item.node.provider.name}</Box>
        {item.node.ceCertification && (
          <Chip size="small" className={chipsClass} label="CE" />)}
        {item.node.fdaCertification && (
          <Chip size="small" className={chipsClass} label="FDA" />)}
        {item.node.research && (
          <Chip size="small" className={chipsClass} label={t('research').toUpperCase()} />)}
        {item.node.category && (
          <Chip size="small" className={chipsClass} label={item.node.category} />)}
        {item.node.speciality && (
          <Chip size="small" className={chipsClass} label={t(item.node.speciality.toLowerCase()).toUpperCase()} />)}
        {item.node.bodyRegion && (
          <Chip size="small" className={chipsClass} label={t(item.node.bodyRegion.toLowerCase()).toUpperCase()} />)}
      </Box>
    );
  };

  const AdvancedToolCollapse = ({ item }) => (
    <Box className={classes.collapsed}>
      <InnerHTML content={!!item.node.info5 && item.node.info5} />
    </Box>
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const advancedTool = dataIA.advancedTools.edges[index].node;

    navigate(`/study/${study.uuid}/advanced-tools/detail/${advancedTool.uuid}`);
  };

  const handleOpen = (evt) => {
    evt.stopPropagation();
    navigate(`/study/${study.uuid}/advanced-tools/detail/${selectedAdvancedTool.uuid}`);
  };

  const toggleFavorite = () => {
    setTriggerToolFavoriteEvent(selectedAdvancedTool.favorite ? 'remove' : 'add');
  };

  const toggleListView = () => {
    setViewAsList(!viewAsList);
  };

  const toggleFiltersNav = () => {
    setViewFilters(!viewFilters);
  };

  const toggleAdvancedToolModal = () => {
    setShowAdvancedToolUse(!showAdvancedToolUse);
  };

  const applyToolHandler = () => {
    if (!selectedAdvancedTool) return;

    if (sessionStorage.getItem(selectedAdvancedTool.uuid) === null) {
      sessionStorage.setItem(selectedAdvancedTool.uuid, (selectedAdvancedTool.creditCost === 0).toString());
    }
    if (sessionStorage.getItem(selectedAdvancedTool.uuid) !== 'true') {
      toggleAdvancedToolModal();
      return;
    }
    setUseTool(true);
  };

  const handleToolError = (error) => {
    if (typeof error === 'string') console.warn(error);
  };

  const goBack = () => navigate(`/study/${study.uuid}`);

  const getFavoriteIcon = () => (selectedAdvancedTool && selectedAdvancedTool.favorite ? <ThumbUp /> : <ThumbUpOutlined />);
  const favoriteLabel = selectedAdvancedTool && selectedAdvancedTool.favorite ? 'remove.from.favorites' : 'add.to.favorites';
  const favoriteDisabled = updatingFavorite || usingTool || !selectedAdvancedTool;
  const getListIcon = () => (viewAsList ? <ViewModule /> : <List />);
  const getFiltersIcon = () => (viewFilters ? <CategoryOutlined /> : <Category />);
  const listLabel = viewAsList ? 'view.cards' : 'view.as.list';
  const filtersLabel = viewFilters ? 'hide.filters' : 'view.filters';
  const disableTools = usingTool || !selectedAdvancedTool;

  const displayedTools = displayTools && displayTools.length;
  const advancedToolsButtons = displayedTools ? [
    { name: favoriteLabel, icon: getFavoriteIcon, handleClick: toggleFavorite, disabled: favoriteDisabled },
    { name: 'tool.info', icon: Info, handleClick: handleOpen, disabled: disableTools },
    { name: 'apply.tool', icon: Memory, handleClick: applyToolHandler, disabled: disableTools },
    { name: 'divider2', type: 'divider' },
    { name: filtersLabel, icon: getFiltersIcon, handleClick: toggleFiltersNav, disabled: false },
    { name: 'clear.filters', icon: SettingsBackupRestore, handleClick: resetFilters, disabled: !viewFilters },
    { name: 'divider2', type: 'divider' },
    { name: listLabel, icon: getListIcon, handleClick: toggleListView, disabled: false },
  ] : [];

  const buttons = [
    ...advancedToolsButtons,
    { name: 'go.back', icon: Close, handleClick: goBack },
  ];

  const infoButton = {
    Icon: Info,
    tooltip: 'tool.info',
  };

  return (
    <ArticleContainerUI>
      <Navbar>
        <SectionBar
          title="alma.advanced.tools"
          subtitle={`${t('displaying.tools')} (${displayedTools})`}
          items={buttons}
          breadcrumbs={getStudyApplicationPath(study)}
        />
      </Navbar>

      <StudyAdvancedToolUseDialog
        open={showAdvancedToolUse && selectedAdvancedTool && !!study}
        onClose={toggleAdvancedToolModal}
        advancedTool={selectedAdvancedTool}
        studyId={study.uuid}
      />

      <AdvancedToolUse
        studyId={study.uuid}
        advancedTool={selectedAdvancedTool}
        setUsingTool={setUsingTool}
        uiToolStatus={uiToolStatus}
        setUiToolStatus={setUiToolStatus}
        useTool={useTool}
        setUseTool={setUseTool}
        setError={handleToolError}
      />

      {selectedAdvancedTool && (
        <AdvancedToolSetFavorite
          advancedTool={selectedAdvancedTool}
          triggerToolEvent={triggerToolFavoriteEvent}
          setTriggerToolEvent={setTriggerToolFavoriteEvent}
          setUpdating={setUpdatingFavorite}
        />
      )}

      <AdvancedToolFilterNav
        open={viewFilters}
        onClose={toggleFiltersNav}
        options={options}
        filters={filters}
        updateFilters={updateFilters}
      />
      <Container maxWidth="xl" className="article">
        <PageContentUI>
          {(loadingIA && isEmpty(dataIA))
            ? <Loading />
            : (
              <CardWrapperUI>
                {!!displayedTools && (
                  viewAsList ? (
                    <TableCollapse
                      items={displayTools}
                      rowActionButton={infoButton}
                      GetTitle={getAdvancedToolName}
                      GetSubtitle={getAdvancedToolData}
                      GetCollapse={AdvancedToolCollapse}
                      selected={selected}
                      setSelected={setSelected}
                      handleGoto={handleGoto}
                      isMore={dataIA
                      && dataIA.advancedTools
                      && dataIA.advancedTools.pageInfo
                      && dataIA.advancedTools.pageInfo.hasNextPage}
                      responsive={false}
                      fetchMoreFn={fetchMoreFunction}
                    />
                  ) : (
                    <FlexGridTable
                      items={displayTools}
                      cardActionButton={infoButton}
                      ImageItem={AdvancedToolCardImageItem}
                      ImageOverlay={AdvancedToolCardImageOverlay}
                      ImageFooter={AdvancedToolCardImageFooter}
                      selected={selected}
                      setSelected={setSelected}
                      handleGoto={handleGoto}
                      isMore={dataIA
                      && dataIA.advancedTools
                      && dataIA.advancedTools.pageInfo
                      && dataIA.advancedTools.pageInfo.hasNextPage}
                      fetchMoreFn={fetchMoreFunction}
                    />
                  )
                )}
                {!displayedTools && (
                  <AlertWrapperUI>
                    <AlertUI severity="warning" title={t('info')}>
                      {t('no.advanced.tools')}
                    </AlertUI>
                  </AlertWrapperUI>
                )}
              </CardWrapperUI>
            )}
        </PageContentUI>
      </Container>
    </ArticleContainerUI>
  );
};
